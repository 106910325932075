import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor( public dialog: MatDialog,) { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }
   /**
   * @author Sushil Hariakar
   * @name openFilterDialog
   * @description this method is used for open the filter dialog
   */
   openFilterDialog() {
    // const obj = {
    //   name: '',
    //   description: '',
    //   image: '',
    // }
    // const dialogRef = this.dialog.open( {
    //   height: '400px',
    //   width: '600px',
    //   data: obj,
    //   autoFocus: false,
    //   position: { top: '0', left: '0' },
    // });
   
    // dialogRef.afterClosed().subscribe(result => {
      
    // });
  }

}
