import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-services-popup',
  templateUrl: './services-popup.component.html',
  styleUrls: ['./services-popup.component.scss']
})
export class ServicesPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ServicesPopupComponent>) { }

  ngOnInit(): void {
  }
  onClose(){
    this.dialogRef.close();
  }

}
