import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ServicesComponent } from './services/services.component';
import { AboutComponent } from './pages/about/about.component';

const routes: Routes = [ { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  {path:'services',component:ServicesComponent},
  {path:'about-us',component:AboutComponent},
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  { path: 'all-service', loadChildren: () => import('./all-services/all-services.module').then(m => m.AllServicesModule) },
  // Wildcard route to redirect to HomeComponent for any unmatched paths
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
