<section class="footer-section row">
  <div class="col-12 col-md-6 col-lg-6 mt-3 mb-3">
      <p >ABOUT US</p>
      <p class="text-secondary">
        We help you find your dream plant
      </p>
      <div class="d-flex justify-content-start align-items-center text-secondary">
        <div class="rounded-circle me-3 border border-secondary d-flex align-items-center justify-content-center" style="width: 40px; height: 40px;">
            <a href="https://in.linkedin.com/company/mechtron-services" target="_blank" rel="noopener"
            rel="nofollow">
            <img height="20px" width="20px" class="img-fluid text-secondary" src="../../../assets/images/linkedin2.svg" alt="">
        </a>
        </div>
        <div class="rounded-circle me-3 border border-secondary d-flex align-items-center justify-content-center" style="width: 40px; height: 40px;">
            <a href="https://instagram.com/mechtronservices" target="_blank" rel="noopener"
            rel="nofollow">
            <img height="20px" width="20px" class="img-fluid text-secondary" src="../../../assets/images/instagram.svg" alt="">
        </a>
        </div>
        <div class="rounded-circle border border-secondary d-flex align-items-center justify-content-center" style="width: 40px; height: 40px;">
            <img height="20px" width="20px" class="img-fluid text-secondary" src="../../../assets/images/facebook.svg" alt="">
        </div>
      </div>
  </div>
  <!-- <div class="col-12 col-md-6 col-lg-3  mt-3 mb-3">
      <p class=" ps-md-3">QUICK LINKS</p>
      <ul class="nav flex-column ps-md-3">
          <li class="nav-item">
              <a class="nav-link" href="#">Effluent Treatment Plant</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" href="#">Dissolved Air Floatation</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" href="#">CPI Separator</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" href="#">Oil Skimmer</a>
          </li>
      </ul>
  </div> -->
  <div class="col-12 col-md-6 col-lg-3  mt-3 mb-3">
      <p class=" ps-md-3">GOOGLE MAP</p>
      <!-- Embed Google Map iframe here -->
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15047.670034717758!2d72.76048203127922!3d19.459123099029206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7aa4714626c57%3A0xefde55c8006fa4f9!2s2%2C%20Agashi%20Rd%2C%20Purapada%2C%20Agashi%2C%20Virar%20West%2C%20Vasai-Virar%2C%20Virar%2C%20Maharashtra%20401303!5e0!3m2!1sen!2sin!4v1726403064353!5m2!1sen!2sin" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29880.18011314281!2d72.88256294793511!3d20.587138156382427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0dd52ab0f567b%3A0x29274d70bf4cf0da!2sTithal%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1716628464785!5m2!1sen!2sin" 
      width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
  </div>
  <div class="col-12 col-md-6 col-lg-3  mt-3 mb-3">
      <p class=" ps-md-3">KEEP IN TOUCH</p>
      <ul class="nav flex-column ps-md-3">
          <li class="nav-item">
              <a class="nav-link text-dark" >Address: B/105, Samta Apartment No 2, Agashi Road, Virar West, Palghar, Maharashtra, 401303</a>
          </li>
          <li class="nav-item">
              <a class="nav-link text-dark" >Phone: +(91)-8983643660</a>
          </li>
          <li class="nav-item">
              <a class="nav-link text-dark" >Email: proposal@mechtronservices.com, info@mechtronservices.com</a>
          </li>
      </ul>
  </div>
  <p class="text-secondary">2024 all Right Reserved Term of use Mechtron</p>
</section>
