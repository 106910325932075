<section class="about-section bg-main">
  <div class="py-5">
    <div class="container ">
      <h2 class="text-center text-dark fw-bold mb-5">About Mechtron Water Services</h2>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <p class="lead text-center mb-4">
            <strong>"Mechtron Water Services"</strong>, a Mechtron Group of Company, is renowned as a leading company providing Waste Water Solutions.
          </p>
          <p class="text-center mb-4">
            We, at <strong>"Mechtron Water Services"</strong>, design, manufacture, supply, install, and commission Waste Water Treatment Plants & Equipment. Our goal is to offer sustainable and eco-friendly solutions while ensuring complete customer satisfaction. We pride ourselves on delivering high-quality plants with cutting-edge technologies, catering to both domestic and industrial sectors.
          </p>
          <p class="text-center mb-4">
            Our range of products includes Packaged MBBR, MBR, SBR, STP, and ETP, oil skimmers, DAF, Lamella Clarifiers, Surface Aerators, Oil Separators, and more.
          </p>
        </div>
      </div>
    </div>
  
    <!-- Meet the Founder Section -->
    <section class="founder-section py-5">
      <div class="container">
        <h3 class="text-center text-dark fw-bold mb-4">Meet the Founder</h3>
        <div class="row align-items-center">
          <div class="col-lg-4 text-center mb-4 mb-lg-0">
            <img src="../../../assets/images/nidhi.JPEG" alt="Nidhi Patel" class="img-fluid rounded-circle founder-image">
          </div>
          <div class="col-lg-8">
            <h4 class="fw-bold text-dark">Nidhi Patel</h4>
            <p class="text-secondary mb-4">
              Nidhi Patel, the visionary founder of <strong>"Mechtron Water Services"</strong>, has been a driving force behind the company's success. With a deep commitment to environmental sustainability and innovation, she has spearheaded the development of modern, eco-friendly wastewater treatment technologies that serve both domestic and industrial sectors.
            </p>
            <p class="text-secondary">
              Under her leadership, <strong>"Mechtron Water services"</strong> continues to advance cutting-edge technologies that not only protect the environment but also enhance wastewater treatment efficiency, delivering exceptional value to clients.
            </p>
            <div class="social-icons mt-4">
              <a href="https://www.linkedin.com/in/nidhi-patel-2b157716a/" target="_blank" class="me-3">
                <img src="../../../assets/images/linkedin2.svg" alt="LinkedIn" class="social-icon">
              </a>
              <a href="https://www.instagram.com/nidhipatel3724/" target="_blank">
                <img src="../../../assets/images/instagram.svg" alt="Instagram" class="social-icon">
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  </section>
  