import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
tab:number=1
  constructor(private router: Router,) { }

  ngOnInit(): void {
  }
  changeTab(value){
    this.tab=value

  }
  isRouteActive(url, matchExcat = false): boolean {
    return this.router.isActive(url, matchExcat);
  }
//   scroll(elementId: string) {
//     const element = document.getElementById(elementId);
//     if (element) {
//         element.scrollIntoView({ behavior: 'smooth' });
//     }
// }
}
