import { style, transition, trigger ,animate} from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import Splide from '@splidejs/splide';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('contentWrapper') contentWrapper!: ElementRef;

  constructor(private renderer: Renderer2,@Inject(PLATFORM_ID) private _platformId: Object,private router: Router) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      const contentWrapperElement = this.contentWrapper.nativeElement;
      const imageUrl = 'assets/images/home-background.webp';

      contentWrapperElement.style.backgroundImage = `url('${imageUrl}')`;
       // Initial carousel setup
       this.initializeCarousel();
    }
  }
  initializeCarousel(): void {
    const splide = new Splide('#image-carousel', {
      type: 'loop',
      perPage: 1,
      autoplay: true,
      interval: 2000,
      speed: 2000,
    });
    splide.mount();
  }
}
export const scrollAnimation = trigger('scrollAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('10s linear', style({ transform: 'translateX(-100%)' })),
  ]),
]);

