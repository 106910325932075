import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgProgressComponent } from 'ngx-progressbar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mechtron-services';
  @ViewChild(NgProgressComponent) lBar: NgProgressComponent;
  showChatText: boolean;
  constructor( public router: Router, @Inject(PLATFORM_ID) private _platformId: Object,){
    if (isPlatformBrowser(this._platformId)) {
      router.events.subscribe((event:any)=>{
        this.loadingBarInterceptor(event);
      })
    }
  }
  private loadingBarTimeoutId: any;
  
  private loadingBarInterceptor(event: Event) {
    if (event instanceof NavigationStart) {
      this.lBar.start();
    } else if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      if (this.loadingBarTimeoutId) {
        clearTimeout(this.loadingBarTimeoutId);
      }

      this.loadingBarTimeoutId = setTimeout(() => {
        this.lBar.complete();
      }, 1000);
    }
  }
  toggleChatText(): void {
    this.showChatText = !this.showChatText;
    this.openWhatsApp();
  }
  openWhatsApp(): void {
    const countryCode = '91'; // Replace with the desired country code
    const phoneNumber = '8983643660'; // Replace with the desired phone number
    const message = 'Hi'; // Replace with the desired message
    const whatsappURL = `https://api.whatsapp.com/send?phone=+${countryCode}${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  }
}
