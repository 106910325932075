

        <h5 class=" text-center">CPI Separator</h5>


 
    <!-- Image of CPI Separator -->
    <div class="text-center mb-3">
        <img src="../../../assets/images/CPI.png" alt="CPI Separator" class="img-fluid" />
      </div>
  
    <p>The CPI separator is a gravity separation device designed based on Stokes' Law principles, defining the rise velocity of oil droplets based on their density, size, and water properties. The separator’s design relies on the specific gravity difference between the oil and wastewater, as this difference is significantly smaller than that between suspended solids and water. Consequently, most suspended solids settle to the bottom as a sediment layer, while the oil rises to the top, with wastewater occupying the middle layer. The CPI Design Standards, when correctly applied, consider the geometry, design, and size adjustments of the separator beyond Stokes' Law principles, including allowances for turbulence losses.</p>
  
    <p>During operation, the oil layer, which may contain entrained water and attached solids, is continually skimmed off, while the heavier bottom sediment layer is removed by draining sludge from the bottom nozzle.</p>
  
    <h6>Benefits:</h6>
    <ul class="list-group mb-3">
      <li class="list-group-item">Effectively dewaters the oil, making oil recovery simpler.</li>
      <li class="list-group-item">Long life and corrosion resistance.</li>
      <li class="list-group-item">Eliminates bridging and plugging of viscous materials.</li>
      <li class="list-group-item">Economical and reliable operations.</li>
      <li class="list-group-item">Low operating and maintenance costs.</li>
      <li class="list-group-item">Gravity operated, so no utilities are required.</li>
    </ul>
  
    <h6>Applications:</h6>
    <ul class="list-group">
      <li class="list-group-item">Reduce waste treatment load.</li>
      <li class="list-group-item">Recover free oil.</li>
      <li class="list-group-item">Refinery wastewater.</li>
      <li class="list-group-item">Sewage Treatment.</li>
    </ul>


  